@import url('../../node_modules/bootstrap/dist/css/bootstrap.css');
@import url('../../node_modules/@speedlo/ui/dist/Flaticon/style.css');

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  font-size: 16px;
  background-color: #ebebeb;
  color: #222222;
  touch-action: pan-x pan-y;
}

.App {
  text-align: center;
}

/*============================================================================*\
    Page Layout
\*============================================================================*/
.row-white {
  background-color: white;
}

@media (max-width: 767px) {
  .container-fluid {
    padding: 0px;
  }
  .pl-sm-0 {
    padding-right: 0;
  }
}

/*============================================================================*\
    Bootstrap styling
\*============================================================================*/
.is-valid.form-control {
  border-color: #e5e5e5;
}
.form-control {
  border-radius: 0.5rem !important;
}
.btn {
  border-radius: 0.4rem;
}
.btn-primary {
  background-color: #99230e;
  border-color: #99230e;
}
.btn-primary:hover {
  background-color: #b22810;
  border-color: #b22810;
}
.btn-success {
  background-color: #54984a;
  border-color: #54984a;
}
.btn-success:hover {
  background-color: #5ca651;
  border-color: #5ca651;
}
.btn-warning {
  background-color: #d9a700;
  border-color: #d9a700;
  color: white;
}
.btn-warning:hover {
  background-color: #e8b200;
  border-color: #d9a700;
  color: white;
}
.btn-info {
  background-color: #e5e5e5;
  border-color: #e5e5e5;
  color: #636363;
}
.btn-info:hover {
  background-color: #ededed;
  border-color: #999;
  color: #636363;
}
.btn.disabled {
  background-color: #d4d4d4;
  opacity: 1;
  border: 0;
}
.btn.disabled:hover {
  background-color: #d4d4d4;
}
