.modal__container .modal-content {
  border-radius: 0.6rem;
}
.modal__header {
  background-color: #2e2e2e;
  color: white;
  font-size: 1.3rem;
  text-align: center;
  border-radius: 0.5rem 0.5rem 0 0;
}
.modal__header button {
  color: white;
}
.modal__header button:hover {
  color: white;
}
.modal__body {
  text-align: center;
  padding: 0;
}
.modal__body__content {
  padding: 1.5rem;
}
.modal__body__food__name {
  font-size: 1.4rem;
  font-weight: bold;
  text-align: left;
}
.modal__body__food_description {
  display: none;
  text-align: left;
}

.modal__body__button__line {
  margin: 0;
  position: sticky;
  bottom: 0;
}
.modal__body__button__strip {
  background-color: #d4d4d4;
  position: relative;
  top: 3.3rem;
  z-index: 0;
  height: 1.6rem;
  width: 100%;
}
.modal__body__button {
  margin: 1rem auto;
  padding: 0.8rem 1.6rem;
  z-index: 1;
  position: relative;
  text-transform: uppercase;
}

.modal-open .modal {
  overflow-y: scroll; /* has to be scroll, not auto */
  -webkit-overflow-scrolling: touch;
}

@media (min-width: 900px) {
  .modal__container {
    max-width: 970px;
    margin: 2rem auto;
  }
}
